.ant-drawer-header {
    border-radius: 0;
  }
  
  .ant-drawer-title {
    color: #000;
  }
  
  .ant-drawer-body {
    color: #000;
    height: calc(100vh - 55px);
  }