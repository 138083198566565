.inputbox-with-one-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}
.inputbox-with-one-input label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #0d0d0d;
}

.inputbox-with-one-input input,
select {
  border: none !important;
  font-size: 16px;
  line-height: 25px;
  border-bottom: 1px solid #c4c4c4 !important;
  padding-bottom: 6px;
  border-radius: 0 !important;
}

.inputbox-with-one-input select,
input {
  padding-left: 0 !important;
}

.inputbox-with-two-input {
  display: flex;
  justify-content: space-between;
}

.inputbox-with-two-input > div {
  width: 45%;
}

.modal-header-sub-data {
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 17px;
  color: #4f4f4f;
  text-align: center;
}

.inputbox-with-OTP-code {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.inputbox-with-OTP-code input {
  width: 80px;
  font-size: 16px;
  border: none;
  line-height: 25px;
  color: #b6b3b3;
  border-bottom: 1px solid #c4c4c4;
  padding-bottom: 6px;
}
