* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: 'Inter', sans-serif;
  height: 100vh;
  overflow-x: hidden;
  width: 100%;
}
.App {
  width: 100%;
  margin-left: 0;
  margin-top: 0;
  height: 100%;
}
:root {
  --border-color: #ff6634;
}
.dropdown {
  width: 200px;
  position: relative;
  user-select: none;
}
.dropdown .dropdown-btn {
  padding: 10px;
  background-color: #ffeae1;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  color: #ff6634;
  width: 181px;
  height: 46px;
  margin-right: -20px;
}
.dropdown .dropdown-content {
  position: absolute;
  top: 110%;
  padding: 10px;
  left: 0;
  background-color: #ffeae1;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #ff6634;
  width: 80%;
}
.dropdown .dropdown-content .dropdown-item {
  padding: 10px;
}
.dropdown .dropdown-content .dropdown-item:hover {
  background: #fde2d5;
}
.export-data {
  background-color: #27ae60;
  font-size: 10px;
}
.generate-code {
  font-size: 10px;
}

.dropdown-content input {
  border: 1px solid #d5d5d5;
  border-radius: 4px;
}
::-webkit-datetime-edit {
  color-scheme: dark;
}

::-webkit-calendar-picker-indicator {
  color: #ff6634 !important;
}

.container {
  display: flex;
  align-items: center;
}

.searchInputs {
  display: flex;
  position: relative;
}
.search {
  border: 1px solid #ff6634;
  border-radius: 6px;
}

.search input {
  border: 0;
  background: #f5f5f5;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin-left: 3rem;
  width: 270px;
  height: 46px;
  display: flex;
}

.searchIcon {
  height: 46px;
  width: 25px;
  display: grid;
  place-items: center;
  color: #e7472c;
  position: absolute;
  left: 10px;
  right: 0;
  top: 0;
  bottom: 0;
}

input:focus {
  outline: none;
}

.start-date {
  color: #e7472c;
  font-size: 10px;
  line-height: 12px;
}
.start-date input {
  color: #e7472c !important;
  font-size: 10px;
  line-height: 12px;
}

.end-date {
  color: #e7472c;
  font-size: 10px;
  line-height: 12px;
}

.searchIcon svg {
  font-size: 23px;
}
.mechanics {
  display: flex;
  margin-left: auto;
  flex-wrap: wrap;
}
.mechanics-icon {
  display: flex;
  color: #fff;
  margin-left: 10px;
}
.mechanics-icon:first-child {
  width: 120px;
  height: 30px;
  background: #27ae60;
  border-radius: 3px;
  line-height: 15px;
  align-items: center;
  padding: 0 10px;
}
.mechanics-icon:nth-child(2) {
  width: 120px;
  height: 30px;
  line-height: 15px;
  background: #ff4b01;
  border-radius: 3px;
  align-items: center;
  padding: 0 5px;
}

.mechanics-icon .icon {
  padding: 5px;
}
.cards-container {
  display: flex;
  border: 1px sold green;
  gap: 15px;
  margin-top: 30px;
}

.cards-container .cards {
  width: 351.52px;
  height: 200px;
  background: #ffffff;
  box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.13);
  border-radius: 5px;
}

.cards-container .cards p,
h2 {
  padding: 20px 10px;
}
.cards-container .cards p {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #263238;
}
.cards-container .cards h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
}

.status-dropdown {
  position: relative;
  width: 150px;
}
.status-dropdown .status-dropdown-btn {
  width: 123px;
  height: 35px;
  background: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  border: none;
  color: #9e9e9e;
}
.status-dropdown .status-dropdown-content {
  color: #9e9e9e;
  background-color: #fff;
  position: absolute;
  z-index: 2;
}
.status-dropdown-item {
  height: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  background-color: #fff;
  color: #9e9e9e;
}
.status-dropdown .status-dropdown-content .status-dropdown-item:hover {
  background-color: #f0efef;
}

.status-container {
  width: 351.52px;
  height: 30px;
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.date-dropdown {
  width: 450px;
  position: relative;
}

.date-dropdown .date-dropdown-btn {
  width: 203px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  height: 35px;
  color: #9e9e9e;
  border: none;
  font-size: 14px;
}

.date-dropdown .date-dropdown-content {
  display: flex;
  justify-content: space-between;
  color: #9e9e9e;
  background-color: #fff;
  position: absolute;
  z-index: 2;
}
.date-dropdown-btn {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  height: 35px;
}
.date-dropdown-item {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
input[type="date"] {
  color: red !important;
}
.date-dropdown .date-dropdown-content .date-dropdown-item:hover {
  background-color: #f0efef;
}
