@import '../../../index.scss';

.bvn-wrapper {
  .modal-header {
    justify-content: center !important;
  }
  .close {
    border: none;
    background: transparent !important;
  }
  .fund-tab-wrap {
    background-color: #f1f7fa;
  }
  .fund-tab {
    font-size: 12px;
    @media (max-width: 426px) {
      font-size: 10px;
    }
  }
  .fund-tab-active {
    color: $primary-color;
  }
  .modal-header {
    border-bottom: none;
    background-color: #f1f7fa;
  }
  .modal-footer {
    border-top: none;
  }
  .modal-footer button {
    width: 80%;
  }
  .input-detail {
    font-size: 10px;
    color: #b6b3b3;
  }
  form {
    width: 80%;
    @media (max-width: 426px) {
      width: 100%;
    }
  }
  .copy-card {
    border: 1px solid #f2f2f2;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    width: 70%;
  }
}

.bvn-wrapper {
  .modal-header {
    padding-bottom: 53px;
  }
  .bvn-why {
    position: relative;
  }
  .bvn-info-div {
    position: absolute;
    background-color: #fff;
    width: 309px;
    right: -185px;
    padding: 30px 40px 30px 40px;
    top: -84px;
    .bvn-reason {
      background: rgba(235, 87, 87, 0.1);
      font-size: 14px;
      border-radius: 4px;
    }
    border-radius: 4px;
  }
  .modal-footer button {
    margin-top: 30px !important;
  }
}
