.tier {
  background-color: #fff;
  padding: 20px;
  font-family: 'Inter';

  .head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #252733;
    text-align: center;
  }

  .info {
    display: flex;
    justify-content: space-between;

    .info-1 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #1c1c1c;
    }

    .info-2 {
      display: flex;
      flex-direction: column;

      .info-2-t {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 9px;
        line-height: 21px;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #333333;
      }

      .info-2-b {
        background: #27ae60;
        border: 0.5px solid #e5e5e5;
        box-sizing: border-box;
        box-shadow: 0px 0px 0px #e5e5e5;
        border-radius: 2px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 21px;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #ffffff;
      }

      .info-2-b-true {
        background: #27ae60;
        border: 0.5px solid #e5e5e5;
        box-sizing: border-box;
        box-shadow: 0px 0px 0px #e5e5e5;
        border-radius: 2px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 21px;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #ffffff;
      }

      .info-2-b-false {
        background: red;
        border: 0.5px solid #e5e5e5;
        box-sizing: border-box;
        box-shadow: 0px 0px 0px #e5e5e5;
        border-radius: 2px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 21px;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #ffffff;
      }
    }
  }

  table {
    .upload1 {
      width: 85px;
      margin: auto;
      bottom: -32px;
      opacity: 0;
      position: relative;
    }

    .upload2 {
      width: 85px;
      margin: auto;
      bottom: 31px;
      opacity: 0;
      position: relative;
    }

    .upload3 {
      position: absolute;
      width: 104px;
      top: 332px;
      opacity: 0;
    }

    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;

    thead,
    tr {
      border-bottom: 1px solid #e0e0e0;
    }

    tr {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #1c1c1c;
    }

    th,
    td {
      // border-right: 1px solid #e0e0e0;
      margin: auto;
      // text-align: center;
    }

    tbody {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;

      color: #1c1c1c;
    }

    .action-appve {
      width: 105px;
      // margin: auto;
    }

    .action {
      width: 105px;
      background: #f8f8f8;
      border: 1px solid #e0e0e0;
      box-shadow: 0px 0px 0px #e5e5e5;
      border-radius: 2px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #1c1c1c;
      padding: 5px 10px;
      margin: auto;
    }

    .action-2 {
      width: 105px;
      background: #f8f8f8;
      border: 1px solid #e0e0e0;
      box-shadow: 0px 0px 0px #e5e5e5;
      border-radius: 2px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #1c1c1c;
      padding: 5px 10px;
      margin-left: 25px;
    }

    .action-3 {
      width: 105px;
      background: #f8f8f8;
      border: 1px solid #e0e0e0;
      box-shadow: 0px 0px 0px #e5e5e5;
      border-radius: 2px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #1c1c1c;
      padding: 5px 10px;
    }

    .action-d {
      width: 200px !important;
    }

    .status {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #27ae60;
      width: fit-content;
      margin: auto;
      text-align: center;
    }

    .status-true {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #27ae60;
      width: fit-content;
      // margin: auto;
      // text-align: center;
    }

    .status-pending {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      color: #d4af37;
      width: fit-content;
      margin: 5px;
      text-align: center;
    }

    .status-false {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: red;
      width: fit-content;
      margin: auto;
      text-align: center;
    }
  }

  .limit {
    .limit-info {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #1c1c1c;
    }
  }

  .tier-foot {
    align-items: center;
    margin-top: 100px;
    display: flex;
    justify-content: center;

    .tier-foot-1 {
      font-family: 'Inter';
      font-style: italic;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #1c1c1c;
    }

    .tier-foot-2 {
      background: #ffffff;
      border: 1px solid #e0e0e0;
      box-sizing: border-box;
      box-shadow: 0px 0px 0px #e5e5e5;
      border-radius: 2px;
      display: flex;
      padding: 0px 3px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 9px;
      line-height: 21px;
      align-items: center;
      letter-spacing: -0.02em;
      cursor: pointer;

      .btn {
        background: #ffffff;
        border: 0 !important;
        box-sizing: border-box;
        box-shadow: 0px 0px 0px #e5e5e5;
        border-radius: 2px;
        padding: 0 5px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 9px;
        line-height: 21px;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #858585;
      }

      color: #858585;

      .page {
        background: #f8f8f8;
        border: 1px solid #e0e0e0;
        box-sizing: border-box;
        box-shadow: 0px 0px 0px #e5e5e5;
        border-radius: 2px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 21px;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #858585;
        padding: 2px 10px;
      }
    }
  }
}

.upload-kyc {
  display: flex;
  width: 100%;
  position: relative;

  img {
    background-size: cover;
    height: 11.2em;
    width: 100%;
  }

  .upload-banner-input,
  .upload-banner-input2 {
    height: 1.8em;
    width: 1.9em;
    left: 15em;
    top: 4.8em;
    position: absolute;
    cursor: pointer;
  }

  .upload-banner-input2 {
    width: 103px;
    opacity: 0;
  }
}