.dispute-top-session {
    background-color: white;
  }
  .search-container {
    display: flex;
    position: relative;
    border: 2px solid #ff6700;
    width: 250px;
    height: 30px;
    background: #F2F2F2;
  border: 0.5px solid #E5E5E5;
  box-shadow: 0px 0px 0px #E5E5E5;
  border-radius: 2px;
  }
  .dispute-filter-container {
    display: flex;
    align-items: baseline;
    margin: 0 auto;
    justify-content: space-around;
    width: 190px;
  }
  .dispute-filter-container > h4{
    font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */
  
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.5);
  }
  
  .filter-icon {
    color: rgba(0, 0, 0, 0.6);
  }
  
  .dispute-search-wrapper {
    display: flex;
    height: 50px;
    align-items: center;
    border-radius: 6px;
  }
  
  .dispute-search-wrapper input {
    border: 0;
    background: #F5F5F5;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    margin-left: 1.6rem;
    width: 270px;
    /* height: 30px; */
    /* display: flex; */
    /* color: #333333; */
  align-items: center;
  letter-spacing: -0.02em;
  color: #333333;
  }
  
  .search-icon {
    /* height: 32px; */
    width: 25px;
    display: grid;
    place-items: center;
    /* color:  #E7472C; */
    position: absolute;
    left: 0.5px;
    right: 0;
    top: 0;
    bottom: 0;
  }
  
  .dispute-card{
  width: 200px;
  height: 119px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  gap:50px;
  }
  
  .green > p, .green > h2{
    color: #27AE60 !important;
  }
  .red > p, .red > h2{
    color: #ff6700 !important;
  }
  .yellow > p, .yellow > h2{
    color: #F68A44 !important;
  }
  
  .mechanics-icon{
    margin-left: auto;
    width: 500;
  }
  
  .mini-cards-container{
    display: flex;
    gap: 15px;
    margin-top: 30px;
    margin-bottom: 30px;
    justify-content:space-between;
  }
  
  .mini-cards-container .single-card{
    display: flex;
    align-items: center;
    justify-content:space-between;
    width: 233px;
    height: 45px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 0 15px 0 10px;
    }
    .mini-cards-container .single-card p{
      margin: 10px 0;
    }
    .mini-cards-container .single-card p:first-child{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  
    }
    .mini-cards-container .single-card p:nth-child(2){
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  
  
  color: #000000;
    }