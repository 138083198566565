$font-stack: 'Work Sans', sans-serif;
$font-d1: 'Libre Baskerville';
$font-text: 'Lato', sans-serif;
$primary: #ff6700;
$primary-color: #ff6700;
$secondary-color: #828282;
$secondary-light: #c4c4c4;
$secondary-dark: #2d2b2a;
$primary-link: #0890a3;
$color-dblue: #064a72;

@import 'bootstrap/scss/bootstrap';
