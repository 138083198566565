.h-dropdown {
  padding-right: 15px;
  position: relative;
  cursor: pointer;
}
.h-dropdown::after {
  opacity: 0.5;
  color: blue;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -2px;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.h-dropdown:hover {
  color: pink;
}
.h-dropdown:hover::after {
  color: pink;
}
.h-avatar {
  background-color: gray !important;
}
[data-kit-theme="dark"] .avatar {
  background: gray !important;
}
